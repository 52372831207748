<template>
  <header class="d-print-none">
    <b-navbar variant="light" fixed="top" class="py-0">
      <router-link to="/" class="navbar-brand text-deleev">Deleev</router-link>
      <b-collapse is-nav>
        <b-navbar-nav class="d-none d-sm-flex">
          <b-nav-item v-if="userIsAdmin" :class="{ active : isActiveRoute('suppliers') }" to="/suppliers" :disabled="isActiveRoute('suppliers')">Fournisseurs</b-nav-item>
          <b-nav-item :class="{ active : isActiveRoute('products') }" to="/products" :disabled="isActiveRoute('products')">Produits</b-nav-item>
          <b-nav-item :class="{ active : isActiveRoute('products-labo') }" to="/products-labo" :disabled="isActiveRoute('products-labo')">Produits LABO</b-nav-item>
          <!--<b-nav-item v-if="userIsAdmin" :class="{ active : isActiveRoute('ubereats') }" to="/ubereats" :disabled="isActiveRoute('ubereats')">Uber Eats</b-nav-item>-->
          <b-nav-item-dropdown v-if="userCanMeS" ref="menuStocks" text="Stocks" :class="{ active : isActiveRoutes(['product-groups', 'product-brands', 'product-industrials', 'supplier-orders-last-arrivals', 'supplier-orders', 'products-logs-pda', 'suppliers']) }">
            <b-dropdown-item :active="isActiveRoute('supplier-orders-last-arrivals')" to="/supplier-orders/last-arrivals">Derniers arrivages</b-dropdown-item>
            <b-dropdown-item :active="isActiveRoute('supplier-orders')" to="/supplier-orders">Liste des commandes</b-dropdown-item>
            <b-dropdown-item v-if="userCanMeSRF" :href="suppliersFormatSupplierClaimsUrl()" target="_blank">Liste des réclamations</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :class="{ active : isActiveRoute('products') }" to="/products" :disabled="isActiveRoute('products')">Produits</b-dropdown-item>
            <b-dropdown-item :class="{ active : isActiveRoute('product-brands') }" to="/product-brands" :disabled="isActiveRoute('product-brands')">Marques (produit)</b-dropdown-item>
            <b-dropdown-item :class="{ active : isActiveRoute('product-industrials') }" to="/product-industrials" :disabled="isActiveRoute('product-industrials')">Industriels / Fabricants (produit)</b-dropdown-item>
            <b-dropdown-item :class="{ active : isActiveRoute('product-groups') }" to="/product-groups" :disabled="isActiveRoute('product-groups')">Groupes (produit)</b-dropdown-item>
            <b-dropdown-item v-if="userIsAdmin" :class="{ active : isActiveRoute('suppliers') }" to="/suppliers" :disabled="isActiveRoute('suppliers')">Fournisseurs</b-dropdown-item>
            <b-dropdown-item v-if="userIsAdmin" :class="{ active : isActiveRoute('ingredients') }" to="/ingredients" :disabled="isActiveRoute('ingredients')">Ingrédients</b-dropdown-item>
            <b-dropdown-item v-if="userIsSuperAdmin" :class="{ active : isActiveRoute('product-typologies') }" to="/product-typologies" :disabled="isActiveRoute('product-typologies')">Typologies de produit</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-if="userIsSuperAdmin" :active="isActiveRoute('products-logs-credits')" to="/products-logs-credits">Logs Crédits</b-dropdown-item>
            <b-dropdown-item v-if="userIsAdmin" :active="isActiveRoute('products-logs-dlctour')" to="/products-logs-dlctour">Logs DLC Tour</b-dropdown-item>
            <b-dropdown-item v-if="userIsAdmin" :active="isActiveRoute('products-logs-pda')" to="/products-logs-pda">Logs PDA</b-dropdown-item>
            <b-dropdown-item v-if="userCanZone" :active="isActiveRoute('products-logs-scan')" to="/products-logs-scan">Logs Scans</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown ref="menuConfigQiQd" text="QI/QD" :class="{ active : isActiveRoutes(['products-qiqd', 'products-qiqd-rules', 'products-sales-stats', 'epds', 'products-reappro']) }">
            <b-dropdown-item v-if="userCanMeSRF" :active="isActiveRoute('products-qiqd')" to="/products-qiqd">QI/QD journalières</b-dropdown-item>
            <b-dropdown-item v-if="userIsSuperAdmin" :active="isActiveRoute('products-qiqd-rules')" to="/products-qiqd-rules">Règles de calcul QI/QD</b-dropdown-item>
            <b-dropdown-divider v-if="userCanMeSRF"></b-dropdown-divider>
            <b-dropdown-item v-if="userIsAdmin" :active="isActiveRoute('epds')" to="/epds">EPDs</b-dropdown-item>
            <b-dropdown-item v-if="userIsAdmin" :active="isActiveRoute('products-reappro')" to="/products-reappro">Produits réappro (hubs/cafets)</b-dropdown-item>
            <b-dropdown-divider v-if="userIsSuperAdmin"></b-dropdown-divider>
            <b-dropdown-item v-if="userIsSuperAdmin" :active="isActiveRoute('products-sales-stats')" to="/products-sales-stats">Stats ventes (réelles & estimations)</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-if="userIsCategoryManager"
            ref="menuRecipes" text="Recettes"
            :class="{ active : isActiveRoutes(['recipes', 'recipes-ingredients', 'recipes-ingredients-products', 'recipes-utensils', 'recipes-measures', 'recipes-stats']) }"
          >
            <b-dropdown-item :active="isActiveRoute('recipes')" to="/recipes/recipes">Recettes</b-dropdown-item>
            <b-dropdown-item :active="isActiveRoute('recipes-ingredients')" to="/recipes/ingredients">Ingrédients</b-dropdown-item>
            <b-dropdown-item :active="isActiveRoute('recipes-ingredients-products')" to="/recipes/ingredients-products">Produits (ingrédients)</b-dropdown-item>
            <b-dropdown-item :active="isActiveRoute('recipes-utensils')" to="/recipes/utensils">Ustensiles</b-dropdown-item>
            <b-dropdown-item :active="isActiveRoute('recipes-measures')" to="/recipes/measures">Mesures</b-dropdown-item>
            <b-dropdown-item :active="isActiveRoute('recipes-tags')" to="/recipes/tags">Tags</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :active="isActiveRoute('recipes-stats')" to="/recipes/stats">Stats</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item :class="{ active : isActiveRoute('barcodes') }" to="/barcodes" :disabled="isActiveRoute('barcodes')">Barcode & Qr-code</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <small class="text-muted mr-1">{{user.email}}</small>
      <b-button variant="light" size="sm" class="text-secondary" @click="$emit('logout')"><i class="fas fa-sign-out-alt"></i> déconnexion</b-button>
    </b-navbar>
  </header>
</template>

<script>
import store from '../store'

export default {
  name: 'Header',
  props: {
    user: Object
  },
  data () {
    return {
      userIsAdmin: store.userCan('admin'),
      userIsSuperAdmin: store.userCan('super-admin'),
      userIsCategoryManager: store.userCan('category-manager'),
      userCanMeS: store.userCan('MeS'),
      userCanMeSRF: store.userCan('MeS RF'),
      userCanZone: store.userCan('Zone')
    }
  },
  watch: {
    $route () {
      if (this.$refs.menuStocks !== undefined) this.$refs.menuStocks.hide(true)
      if (this.$refs.menuConfigQiQd !== undefined) this.$refs.menuConfigQiQd.hide(true)
      if (this.$refs.menuRecipes !== undefined) this.$refs.menuRecipes.hide(true)
    }
  },
  methods: {
    isActiveRoute (route) {
      return route === this.$route.name
    },
    isActiveRoutes (routes) {
      return routes.find(route => route === this.$route.name)
    }
  }
}
</script>

<style scoped>
  header nav .navbar-brand {
    font-size: 1.54em;
    text-decoration: none;
  }
</style>
