'use strict'

import { ApiDeleev, ApiLabellevieConfig, FrontLabellevie } from '../index'
import s3 from '../s3.js'

const _getSupplierOrders = function (params) {
  if (params.with_big_delta === '1') {
    params.excluded_minimum_absolute_delta_for_invoice_and_received = 0.05
  }
  if (params.with_big_delta === '0') {
    params.included_maximum_absolute_delta_for_invoice_and_received = 0.05
  }
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/order_forms/', { params: params })
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve({
          supplierOrders: response.data.results,
          count: response.data.count
        })
      })
      .catch(reject)
  })
}

const _getSupplierOrderComments = function (params) {
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/order_form_comments/', { params: params })
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve({
          supplierOrderComments: response.data
        })
      })
      .catch(reject)
  })
}

const _addCommentsDataToSupplierOrders = async function (supplierOrdersList) {
  if (supplierOrdersList) {
    // We add the data of the comments
    let ids = []
    for (let i = 0; i < supplierOrdersList.length; ++i) {
      ids.push(supplierOrdersList[i].id)
    }
    const commentsData = await _getSupplierOrderComments({ order_form_ids: ids })

    let comments = {}
    if (commentsData.supplierOrderComments) {
      for (let i = 0; i < commentsData.supplierOrderComments.length; ++i) {
        let comment = commentsData.supplierOrderComments[i]
        if (comments[comment.order_form_id] === undefined) {
          comments[comment.order_form_id] = [comment]
        } else {
          comments[comment.order_form_id].push(comment)
        }
      }
    }
    for (let i = 0; i < supplierOrdersList.length; ++i) {
      if (comments[supplierOrdersList[i].id] === undefined) {
        supplierOrdersList[i].comments = []
      } else {
        supplierOrdersList[i].comments = comments[supplierOrdersList[i].id]
      }
    }
  }
  return supplierOrdersList
}

const _getSupplierOrdersWithComments = async function (params) {
  const data = await _getSupplierOrders(params)
  if (data.supplierOrders) {
    data.supplierOrders = await _addCommentsDataToSupplierOrders(data.supplierOrders)
  }
  return data
}

const _getSupplierOrderItemClaims = function (params) {
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/order_form_item_claims/', { params: params })
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve(response.data)
      })
      .catch(reject)
  })
}

const _getSupplierOrderItemLogStockAreas = function (params) {
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/order_form_item_logstock_areas/', { params: params })
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve(response.data)
      })
      .catch(reject)
  })
}

const _getProducts = function (params) {
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/order_forms/get_products/', { params: params })
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve(response.data)
      })
      .catch(reject)
  })
}

const _getSupplierOrderTemplates = function () {
  const params = {}
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/supplier_order_templates/', { params: params })
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve({
          supplierOrderTemplates: response.data.results
        })
      })
      .catch(reject)
  })
}

const _getSupplierOrderEmailData = function (id) {
  return new Promise((resolve, reject) => {
    ApiDeleev.get('/order_forms/' + id + '/email_data/')
      .then(response => {
        if (response.status >= 400) return reject(response)
        resolve({
          email_data: response.data
        })
      })
      .catch(reject)
  })
}

const _updateSupplierOrder = function (id, data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .patch(`/order_forms/${id}/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _updateSupplierOrderAdministrativeStatus = function (id, data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${id}/administrative_status/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _updateSupplierOrderStatus = function (id, data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${id}/change_status/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _unmergeSupplierOrder = function (id) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${id}/unmerge/`)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _massActionOnSupplierOrder = function (massAction, data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${massAction}/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _setSupplierOrderDone = function (id) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${id}/done/`)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _importDeliveryNoteForSupplierOrder = function (id, formData) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(
        `/order_forms/${id}/import_delivery_note/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _reinitDeliveryNoteImport = function (id) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${id}/reinit_delivery_note_import/`)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _addCommentOnSupplierOrder = function (id, message) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/${id}/log/`, { 'notes': message })
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _addStock = function (
  quantityToAdd,
  productId,
  supplierOrderId,
  where,
  area,
  shelf,
  tier
) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(
        '/order_forms/arrival/',
        {
          'quantity': quantityToAdd,
          'product_id': productId,
          'order_form_id': supplierOrderId,
          'where': where,
          'area': area,
          'shelf': shelf,
          'tier': tier
        }
      )
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _uploadEvidenceFile = async function (orderFormItemId, inputFile) {
  return s3.uploadToS3AndRegister(
    inputFile,
    '/order_form_item_claims/' + orderFormItemId + '/claim_item_register_file/',
    'order_form_item_claim',
    orderFormItemId
  )
}

const _sendOrderExternalEmail = function (supplierId, orderId, params) {
  return new Promise((resolve, reject) => {
    FrontLabellevie
      .post(`/api/v1/orderssuppliers/${supplierId}/order/${orderId}`, params, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(reject)
  })
}

const _updateSupplierOrderItem = function (id, data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .patch(`/order_form_items/${id}/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _createSupplierOrder = function (data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .post(`/order_forms/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { reject(error) })
  })
}

const _updateSupplierOrderComment = function (id, data) {
  return new Promise((resolve, reject) => {
    ApiDeleev
      .patch(`/order_form_comments/${id}/`, data)
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(reject)
  })
}

export default {
  getSupplierOrders: _getSupplierOrders,
  getSupplierOrderComments: _getSupplierOrderComments,
  getSupplierOrdersWithComments: _getSupplierOrdersWithComments,
  getSupplierOrderItemClaims: _getSupplierOrderItemClaims,
  getSupplierOrderItemLogStockAreas: _getSupplierOrderItemLogStockAreas,
  getProducts: _getProducts,
  getSupplierOrderTemplates: _getSupplierOrderTemplates,
  getSupplierOrderEmailData: _getSupplierOrderEmailData,
  addCommentsDataToSupplierOrders: _addCommentsDataToSupplierOrders,
  updateSupplierOrder: _updateSupplierOrder,
  updateSupplierOrderAdministrativeStatus: _updateSupplierOrderAdministrativeStatus,
  updateSupplierOrderStatus: _updateSupplierOrderStatus,
  unmergeSupplierOrder: _unmergeSupplierOrder,
  massActionOnSupplierOrder: _massActionOnSupplierOrder,
  setSupplierOrderDone: _setSupplierOrderDone,
  importDeliveryNoteForSupplierOrder: _importDeliveryNoteForSupplierOrder,
  reinitDeliveryNoteImport: _reinitDeliveryNoteImport,
  addCommentOnSupplierOrder: _addCommentOnSupplierOrder,
  addStock: _addStock,
  uploadEvidenceFile: _uploadEvidenceFile,
  sendOrderExternalEmail: _sendOrderExternalEmail,
  updateSupplierOrderItem: _updateSupplierOrderItem,
  createSupplierOrder: _createSupplierOrder,
  updateSupplierOrderComment: _updateSupplierOrderComment
}
