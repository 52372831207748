'use strict'

import store from '@/store'
import mbObject from './objects'

const _disponibilities = {
  order: { key: 'order', label: 'disponible', classes: { text: 'text-success', badge: 'badge-success', variant: 'success' } },
  unavailable: { key: 'unavailable', label: 'indisponible', classes: { text: 'text-danger', badge: 'badge-danger', variant: 'danger' } },
  sourcing: { key: 'sourcing', label: 'à sourcer', classes: { text: 'text-warning', badge: 'badge-warning', variant: 'warning' } },
  out_of_season: { key: 'out_of_season', label: 'hors saison', classes: { text: 'text-warning', badge: 'badge-warning', variant: 'warning' } },
  abandoned: { key: 'abandoned', label: 'abandonné', classes: { text: 'text-secondary', badge: 'badge-secondary', variant: 'secondary' } },
  import_frichti: { key: 'import_frichti', label: 'import frichti', classes: { text: 'text-secondary', badge: 'badge-secondary', variant: 'secondary' } }
}

const _zonesMap = {
  8: ' - surgelés'
}
const _zonesList = []
let _allZones = []
_allZones = _allZones.concat(['0', '8'])
_allZones = _allZones.concat(['10-99', '100-199', '200-299', '300-399', '400-499', '500-599', '600-699', '701-799', '801-899', '900-999'])
_allZones.forEach((i) => {
  _zonesList.push({ key: i, label: i + (_zonesMap[i] || '') })
})

const _vatsList = [
  { value: 5.5, text: '5,5%' },
  { value: 10, text: '10%' },
  { value: 20, text: '20%' }
]

const _sellingMethodsList = [
  { value: 'unit', text: 'À l\'unité' },
  { value: 'weight', text: 'Au kilo' }
]

const _mesureUnitsList = [
  { value: 'liter', text: 'Litre' },
  { value: 'kg', text: 'Kg' }
]

const _homemadeTypesList = [
  { value: 'null', text: 'Aucune' },
  { value: 'easy', text: 'Simple' },
  { value: 'complex', text: 'Complexe' }
]

const _buyingMethodsList = [
  { value: 'kilo', text: 'Au kilo' },
  { value: 'unit', text: 'Pièce' }
]

const _buyingTypesList = [
  { value: 'free', text: 'Libre' },
  { value: 'pack', text: 'Colis' }
]

const _primeurCalibreList = [
  { value: null, text: 'Aucun' },
  { value: 1, text: 'très petit calibre' },
  { value: 2, text: 'petit calibre' },
  { value: 3, text: 'calibre moyen' },
  { value: 4, text: 'gros calibre' },
  { value: 5, text: 'très gros calibre' }
]

const _promoReasons = [
  { value: 100, text: 'Promo Indifférent' },
  { value: 1, text: 'Manuel' },
  { value: 2, text: 'DLC' },
  { value: 3, text: 'Permanente' },
  { value: 0, text: 'Pas de Promo' }
]

const _organidationList = [
  { value: 1, text: 'LGEP' }
]

const _logsPdaActions = [
  { value: 'disable', text: '', variant: 'light', klass: 'text-muted' },
  { value: 'stock', text: 'stock', variant: 'light', klass: 'text-success' },
  { value: 'stocked', text: 'stocké', variant: 'success', klass: '' },
  { value: 'update', text: 'rezoner', variant: 'light', klass: 'text-danger' },
  { value: 'updated', text: 'rezoné', variant: 'danger', klass: '' },
  { value: 'destock', text: 'déstocker', variant: 'light', klass: 'text-secondary' },
  { value: 'destocked', text: 'déstocké', variant: 'secondary', klass: '' },
  { value: 'alterstock', text: 'stock visuel', variant: 'light', klass: 'text-primary' },
  { value: 'alteredstock', text: 'stock corrigé', variant: 'primary', klass: '' },
  { value: 'rezoner', text: 'replacer', variant: 'info', klass: '' },
  { value: 'antigaspi-start', text: 'Anti-Gaspi', variant: 'light', klass: 'text-primary' },
  { value: 'antigaspi-add', text: 'Anti-Gaspi - ajout', variant: 'light', klass: 'text-dark' }
]
const _logPdaAction = (action) => _logsPdaActions.find(o => o.value === action) || { value: action, text: '?', variant: 'light', klass: '' }

const _disponibility = (disponibility) => {
  if (!disponibility) return _disponibilities

  return _disponibilities[disponibility] || { label: disponibility, classes: { text: 'text-info', badge: 'badge-info' } }
}

const _formatLabellevieFrontProductUrl = (id, name) => {
  return `${process.env.VUE_APP_LABELLEVIE_FRONT_BASE_URL}/#/p/${id}/${name}`
}

const _formatOpenFoodFactsUrl = (barcode) => {
  return `https://fr.openfoodfacts.org/produit/${barcode}`
}

const _formatCoursesuUrl = (ref, name) => {
  return `https://www.coursesu.com/p/${name}/${ref}.html`
}

const _pricecomparisonConfigs = {
  primenow: { name: 'Prime Now', icon: 'fab fa-amazon' },
  coursesu: { name: 'Courses U', icon: 'fas fa-magnet' },
  systemu: { name: 'Système U', icon: 'fas fa-magnet' },
  leclercdrive: { name: 'Leclerc', icon: 'fas fa-stop-circle' },
  monoprix: { name: 'Monoprix', icon: 'fab fa-medium-m' },
  greenweez: { name: 'Greenweez', icon: 'fab fa-forumbee' },
  naturalia: { name: 'Naturalia', icon: 'fab fa-medium-m' },
  myamericanmarket: { name: 'My American Market', icon: 'fab fa-autoprefixer' },
  carrefour: { name: 'Carrefour', icon: 'fab fa-cuttlefish' },
  houra: { name: 'Houra', icon: 'fas fa-h-square' }
}

const _validationErrorStatusMap = {
  warning: { label: 'Avertissement', variant: 'warning' },
  error: { label: 'Erreur', variant: 'danger' },
  ok: { label: 'OK', variant: 'success' }
}

const _keysToClone = [
  'barcode', 'ingredient_id', 'selling_name', 'brand', 'industrial',
  'purchase_price', 'vat',
  'keywords', 'additional_information', 'informations', 'allergens', 'info_conso', 'ingredients', 'nutrition_facts',
  'disponibility', 'selling_method', 'mesure_unit', 'selling_unit_name', 'quantity', 'pack', 'unit_in_product',
  'tranche', 'weight_with_packing', 'volume', 'unit',
  'do_not_order', 'max_quantity_by_order',
  'is_security_margin_enabled', 'security_margin_percent', 'loss_margin', 'dlc_management_enabled', 'days_before_expiry', 'lifetime_days',
  'retention_periods',
  'bio', 'red_label', 'aop', 'homemade', 'is_frozen', 'alcohol', 'jus', 'fruits_secs', 'cuisine_chaude', 'petite_fraiche_decoupe', 'montage_salade',
  'is_starter', 'is_main_course', 'is_dessert', 'is_restaurant_ticket_eligible',
  'trancheuse', 'mise_en_pot', 'is_course', 'needs_cutlery', 'can_not_be_added_to_an_order_by_the_client', 'is_show_weight_price', 'is_show_listing_weight_price',
  'is_priority_acquisition', 'attention_particuliere', 'no_france_delivery', 'special_delivery_fee', 'special_commerciaux', 'is_pro', 'reset_stock_each_day',
  'auto_missing_type', 'is_big_product', 'qi_scheduling', 'is_kpi_labo', 'is_free_zoning_enabled',
  'homemade_type', 'jour_prise_en_compte', 'prep_time', 'internal_reference', 'prep_instruction', 'start_shipping_from', 'end_shipping_from', 'excluded_days',
  'supplierreferences', 'sub_products', 'stock_quantity_group_id', 'prime_gross_selling_price', 'typology', 'is_staff_discount_allowed', 'area_classifications',
  'add_weight_in_description'
]
const _keysByCentersToClone = [
  'stock_quantity', 'quantity_ideal', 'quantity_threshold', 'area', 'shelf', 'tier', 'unlimited_stocks', 'unlimited_stocks_onoff',
  'is_order_asif_stock_empty', 'is_order_asif_no_order', 'center'
]
const _keysBySitesToClone = [
  'gross_selling_price', 'categories', 'category', 'site', 'is_on_permanent_sale', 'permanent_sale_rate',
  'needs_subscription'
]
const _keysByCentersAndSitesToClone = [
  'is_on_sale', 'sale_rate', 'on_sale_auto_enabled', 'center', 'site'
]

const _keysCloneToUpdate = {
  max_quantity_by_order: 0,
  qi_scheduling: false,
  do_not_order: false,
  barcode: '',
  internal_reference: '',
  brand: null,
  industrial: null,
  is_frozen: false,
  is_staff_discount_allowed: true,
  area_classifications: [
    // { center: 9, classification: 2 }
  ]
}
const _keysByCentersCloneToUpdate = {
  stock_quantity: 0,
  quantity_ideal: 0,
  quantity_threshold: 0,
  area: 0,
  shelf: 0,
  tier: 0,
  unlimited_stocks: false,
  unlimited_stocks_onoff: false,
  is_order_asif_no_order: false,
  is_order_asif_stock_empty: false
}
const _keysByCentersAndSitesCloneToUpdate = {
  is_on_sale: false
}
const _cloneProduct = (product) => {
  let _product = mbObject.pick(product, _keysToClone)
  Object.entries(_keysCloneToUpdate).forEach(([key, value]) => {
    _product[key] = value
  })
  // By Centers
  _product.by_centers = product.by_centers.map(productcenter => {
    let _productcenter = mbObject.pick(productcenter, _keysByCentersToClone)
    Object.entries(_keysByCentersCloneToUpdate).forEach(([key, value]) => {
      _productcenter[key] = value
    })
    return _productcenter
  })
  // By Sites
  _product.by_sites = product.by_sites.map(productsite => {
    let _productsite = mbObject.pick(productsite, _keysBySitesToClone)
    return _productsite
  })
  // By Centers and Sites
  _product.by_centers_and_sites = product.by_centers_and_sites.map(productcentersite => {
    let _productcentersite = mbObject.pick(productcentersite, _keysByCentersAndSitesToClone)
    Object.entries(_keysByCentersAndSitesCloneToUpdate).forEach(([key, value]) => {
      _productcentersite[key] = value
    })
    return _productcentersite
  })

  _product.by_centers.sort((a, b) => a.center - b.center)
  _product.by_sites.sort((a, b) => a.site - b.site)
  _product.by_centers_and_sites.sort((a, b) => a.site - b.site).sort((a, b) => a.center - b.center)

  _product.selling_name = `Copie de ${_product.selling_name}`
  _product.supplierreferences = _product.supplierreferences.map(function (ref) {
    const { id, product, ...result } = ref
    result.supplier_reference = ''
    if (typeof result.supplier === 'object') result.supplier = result.supplier.id
    return result
  })
  _product.sub_products = _product.sub_products.map(function (subproduct) {
    const { id, ...result } = subproduct
    delete result.super_product
    return result
  })
  return _product
}

const _keysToSuper = [
  'selling_name',
  'purchase_price', 'vat',
  'keywords', 'additional_information', 'informations', 'allergens', 'info_conso', 'ingredients', 'nutrition_facts',
  'disponibility', 'selling_method', 'mesure_unit', 'selling_unit_name', 'quantity', 'pack', 'unit_in_product',
  'tranche', 'weight_with_packing', 'volume', 'unit',
  'do_not_order', 'max_quantity_by_order',
  'is_security_margin_enabled', 'security_margin_percent', 'loss_margin', 'dlc_management_enabled', 'days_before_expiry', 'lifetime_days',
  'retention_periods',
  'bio', 'red_label', 'aop', 'homemade', 'is_frozen', 'alcohol', 'jus', 'fruits_secs', 'cuisine_chaude', 'petite_fraiche_decoupe', 'montage_salade',
  'is_starter', 'is_main_course', 'is_dessert', 'is_restaurant_ticket_eligible',
  'trancheuse', 'mise_en_pot', 'is_course', 'needs_cutlery', 'can_not_be_added_to_an_order_by_the_client', 'is_show_weight_price', 'is_show_listing_weight_price',
  'is_priority_acquisition', 'attention_particuliere', 'no_france_delivery', 'special_delivery_fee', 'special_commerciaux', 'is_pro', 'reset_stock_each_day',
  'auto_missing_type', 'is_big_product', 'qi_scheduling', 'is_kpi_labo',
  'homemade_type', 'jour_prise_en_compte', 'prep_time', 'internal_reference', 'prep_instruction', 'start_shipping_from', 'end_shipping_from', 'excluded_days',
  'sub_products', 'prime_gross_selling_price', 'typology', 'is_staff_discount_allowed', 'area_classifications',
  'add_weight_in_description'
]
const _keysByCentersToSuper = [
  'stock_quantity', 'quantity_ideal', 'quantity_threshold', 'area', 'shelf', 'tier', 'unlimited_stocks', 'unlimited_stocks_onoff',
  'is_order_asif_stock_empty', 'is_order_asif_no_order', 'center'
]
const _keysBySitesToSuper = [
  'gross_selling_price', 'categories', 'category', 'site', 'is_on_permanent_sale', 'permanent_sale_rate',
  'needs_subscription'
]
const _keysByCentersAndSitesToSuper = [
  'is_on_sale', 'sale_rate', 'on_sale_auto_enabled', 'center', 'site'
]

const _keysSuperToUpdate = {
  max_quantity_by_order: 0,
  qi_scheduling: false,
  do_not_order: false,
  barcode: '',
  internal_reference: '',
  is_frozen: false,
  is_staff_discount_allowed: true,
  area_classifications: [
    // { center: 9, classification: 2 }
  ]
}
const _keysByCentersSuperToUpdate = {
  stock_quantity: 0,
  quantity_ideal: 0,
  quantity_threshold: 0,
  area: 0,
  shelf: 0,
  tier: 0,
  unlimited_stocks: false,
  unlimited_stocks_onoff: false,
  is_order_asif_no_order: false,
  is_order_asif_stock_empty: false
}
const _keysByCentersAndSitesSuperToUpdate = {
  is_on_sale: false,
  on_sale_auto_enabled: false
}

const _superProduct = (product) => {
  let _product = mbObject.pick(product, _keysToSuper)
  Object.entries(_keysSuperToUpdate).forEach(([key, value]) => {
    _product[key] = value
  })
  // By Centers
  _product.by_centers = product.by_centers.map(productcenter => {
    let _productcenter = mbObject.pick(productcenter, _keysByCentersToSuper)
    Object.entries(_keysByCentersSuperToUpdate).forEach(([key, value]) => {
      _productcenter[key] = value
    })
    return _productcenter
  })
  // By Sites
  _product.by_sites = product.by_sites.map(productsite => {
    let _productsite = mbObject.pick(productsite, _keysBySitesToSuper)
    return _productsite
  })
  // By Centers and Sites
  _product.by_centers_and_sites = product.by_centers_and_sites.map(productcentersite => {
    let _productcentersite = mbObject.pick(productcentersite, _keysByCentersAndSitesToSuper)
    Object.entries(_keysByCentersAndSitesSuperToUpdate).forEach(([key, value]) => {
      _productcentersite[key] = value
    })
    return _productcentersite
  })

  _product.by_centers.sort((a, b) => a.center - b.center)
  _product.by_sites.sort((a, b) => a.site - b.site)
  _product.by_centers_and_sites.sort((a, b) => a.site - b.site).sort((a, b) => a.center - b.center)

  var _pack = (product.supplierreferences[0] || {}).supplier_pack || 1
  _product.selling_name = `${product.selling_name} (x ${_pack})`
  _product.sub_products = [{
    product: product.id,
    sub_disponibility: product.disponibility,
    sub_selling_name: product.selling_name,
    quantity: _pack,
    is_visible: true
  }]
  return _product
}

const _formatProductPDALogs = (logs, equipments) => {
  logs.forEach(o => {
    o.source_id = 0
    o.is_stock_action = ['stock', 'stocked'].indexOf(o.action) !== -1
    o.marked = false
    o._action = _logPdaAction(o.action)

    o.device = ''
    if (o.serial_number && equipments[o.serial_number]) {
      o.device = equipments[o.serial_number].name
    } else if (o.mac_address && equipments[o.mac_address]) {
      o.device = equipments[o.mac_address].name
    } else if (o.serial_number || o.mac_address) {
      o.device = [o.serial_number, o.mac_address].join(' ').trim()
    }

    let _klass = []
    if (o.is_stock_action) {
      if (o.product_quantity !== o.product_quantity_to_add) {
        o.marked = true
        _klass.push('lbv-table-warning')
      } else if (o.supplier_order_id <= 0 || !o.completed) {
        o.marked = true
        _klass.push('lbv-table-secondary')
      } else if (+o.recurrence > 1 && +o.product_quantity_sum !== +o.product_quantity_to_add_sum) {
        o.marked = true
      }
    }
    o._klass = _klass.join(' ').trim()
  })
  return logs
}

const _convertBuyingQuantityToStockQuantity = (
  buyingMethod,
  buyingType,
  buyingPackaging,
  buyingQuantity,
  product
) => {
  let stockQuantity = buyingQuantity
  if (buyingType === 'pack') {
    stockQuantity *= buyingPackaging
  }
  if (buyingMethod === 'kilo') {
    if (buyingType === 'free') {
      stockQuantity *= 1000
    }
    if (product.selling_method === 'unit') {
      stockQuantity = Math.floor(stockQuantity / (product.tranche * 1000))
    }
  }

  if (buyingMethod === 'unit' && product.selling_method === 'weight') {
    stockQuantity *= (product.tranche * 1000)
  }
  return stockQuantity
}

const _regexShippingSpecialRule = /^de (lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche) (\d{1,2})h(\d{0,2}) à (lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche) (\d{1,2})h(\d{0,2}) : \+(\d+)(h|j)$/i
const _regexShippingSpecialRuleDelayDate = /^à partir du (\d{4}(?:-|\/)\d{2}(?:-|\/)\d{2}) à (\d{1,2})h(\d{0,2})$/i
const _isValidShippingSpecialRules = (rules) => {
  if (!rules) return true
  return !rules.split(',').find(rule => !_isValidShippingSpecialRule(rule.trim()))
}
const _isValidShippingSpecialRule = (rule) => {
  if (!rule) return false
  return rule.startsWith('à partir') ? _regexShippingSpecialRuleDelayDate.exec(rule) : _regexShippingSpecialRule.exec(rule)
}

const _regexShippingExcludeSlotsRule = /^de (lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche) (\d{1,2})h(\d{0,2}) à (lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche) (\d{1,2})h(\d{0,2})$/i
const _isValidShippingExcludeSlotsRules = (rules) => {
  if (!rules) return true
  return !rules.split(',').find(rule => !_isValidShippingExcludeSlotsRule(rule.trim()))
}
const _isValidShippingExcludeSlotsRule = (rule) => {
  if (!rule) return false
  return _regexShippingExcludeSlotsRule.exec(rule)
}

const _supplerAllowAutoGenerateBarecode = (supplierId) => {
  const _supplier = store.getSupplier(supplierId)
  if (_supplier && _supplier.category && [1, 6].indexOf(_supplier.category) !== -1) { // all primeur/labo
    return true
  }
  return [
    173, 481, 549, 40, 71, 230, 231, 129, 461, 299, 225, 39, 214, 273, 291, 2, // Primeur
    205, 121, 407, 443, 293, // LABO
    53, 60, 218, 287, 312, 325, 385, // Boulangerie
    43, 133, 107, 153, 201, 199, 202, 144, 98, 167, 308, 345, 141, 204, 143, 251, 305, 105, 5, 207, 97, 377, 172, 297 // Artisants
  ].indexOf(supplierId) !== -1
}

const _getQuantityColisLabel = (quantity, supplierReference, productcenter, isSystemuLgv) => {
  let _packaging = supplierReference.packaging
  let _supplierPack = supplierReference.supplier_pack || 1
  let _isUnpack = supplierReference.is_unpack
  if (productcenter && productcenter.packaging) {
    _packaging = productcenter.packaging
    _supplierPack = productcenter.supplier_pack || 1
    _isUnpack = productcenter.is_unpack
  }
  if (!isSystemuLgv && _isUnpack && _supplierPack) {
    _packaging = Math.ceil(_packaging / _supplierPack)
  }
  let _orderQuantity = 1
  if (quantity > _packaging) {
    _orderQuantity = Math.ceil(quantity / _packaging)
  }
  if (isSystemuLgv) {
    _orderQuantity += 1
  }

  let _quantityLabel = {
    order_quantity: _orderQuantity
  }

  if (supplierReference.buying_type === 'free') {
    _quantityLabel.denomination = `${_orderQuantity} pièce${_orderQuantity > 1 ? 's' : ''}`

    return _quantityLabel
  }

  if (isSystemuLgv) {
    _quantityLabel.denomination = `${_orderQuantity} palette${_orderQuantity > 1 ? 's' : ''} de ${_packaging} pièce${_packaging > 1 ? 's' : ''}`
  } else {
    let _mesure = 'pièce'
    if (supplierReference.buying_method === 'kilo') {
      _packaging = _packaging / 1000
      _mesure = 'kg'
    }
    _quantityLabel.denomination = `${_orderQuantity} colis de ${_packaging} ${_mesure}${_packaging > 1 ? 's' : ''}`
  }

  return _quantityLabel
}

const _isStandardAlternateKey = (alternateKey) => {
  let parts = alternateKey.split('-')
  return (
    parts.length === 2 &&
    parts[0] === (parseInt(parts[0])).toString(10) &&
    parts[1] === (parseInt(parts[1])).toString(10)
  )
}

const _formatProductTranche = (tranche) => {
  return tranche >= 1 ? `${tranche}kg` : `${tranche * 1000}g`
}

const _formatLgepProductExtraInfos = product => {
  const { quantity, unit_notation: unitNotation, brand_id: brandId } = product
  let extraInfos = ''

  if (quantity && unitNotation) {
    extraInfos += `${quantity.toFixed(0)} ${unitNotation}`
  }

  const brand = store.getProductBrand(brandId)
  if (brand) {
    extraInfos += `, ${brand.name}`
  }
  return extraInfos
}

// EPDs

const _seasonalityOptions = [
  { value: '', text: '-' },
  { value: 'valid', text: 'Valide' },
  { value: 'invalid', text: 'Invalide' }
]

const _epdsType = [
  { value: '', text: '-' },
  { value: 'STARTER', text: 'Entrées' },
  { value: 'MAIN', text: 'Plats' },
  { value: 'DESSERT', text: 'Desserts' }
]

export default {
  isValidShippingSpecialRules: _isValidShippingSpecialRules,
  isValidShippingSpecialRule: _isValidShippingSpecialRule,
  isValidShippingExcludeSlotsRules: _isValidShippingExcludeSlotsRules,
  isValidShippingExcludeSlotsRule: _isValidShippingExcludeSlotsRule,
  cloneProduct: _cloneProduct,
  superProduct: _superProduct,
  disponibility: _disponibility,
  validationErrorStatusMap: () => _validationErrorStatusMap,
  primeurCalibres: () => _primeurCalibreList,
  promoReasons: () => _promoReasons,
  getPromoReason: (id) => _promoReasons.find(o => o.value === id) || { value: id, text: '?' },
  getPrimeurCalibre: (calibre) => _primeurCalibreList.find(o => o.value === calibre),
  organidationList: () => _organidationList,
  getOrganidation: (id) => _organidationList.find(o => o.value === +id) || { value: +id, text: '?' },
  vats: () => _vatsList,
  zones: () => _zonesList,
  sellingMethods: () => _sellingMethodsList,
  getSellingMethod: (method) => _sellingMethodsList.find(o => o.value === method) || { value: method, text: 'Inconnue' },
  mesureUnits: () => _mesureUnitsList,
  homemadeTypes: () => _homemadeTypesList,
  buyingMethodsList: () => _buyingMethodsList,
  buyingMethod: (method) => _buyingMethodsList.find(o => o.value === method),
  buyingTypesList: () => _buyingTypesList,
  buyingTypes: (type) => _buyingTypesList.find(o => o.value === type),
  formatProductTranche: _formatProductTranche,
  formatLgepProductExtraInfos: _formatLgepProductExtraInfos,
  formatLabellevieFrontProductUrl: _formatLabellevieFrontProductUrl,
  formatOpenFoodFactsUrl: _formatOpenFoodFactsUrl,
  formatCoursesuUrl: _formatCoursesuUrl,
  pricecomparisonConfigs: () => _pricecomparisonConfigs,
  logPdaAction: _logPdaAction,
  formatProductPDALogs: _formatProductPDALogs,
  convertBuyingQuantityToStockQuantity: _convertBuyingQuantityToStockQuantity,
  supplerAllowAutoGenerateBarecode: _supplerAllowAutoGenerateBarecode,
  getProductCorner: (cornerId) => store.getProductCorner(cornerId),
  getProductTypology: (typologyId) => store.getProductTypology(typologyId),
  getProductBrand: (brandId) => store.getProductBrand(brandId) || {},
  getQuantityColisLabel: _getQuantityColisLabel,
  isStandardAlternateKey: _isStandardAlternateKey,
  getSeasonalityOptions: () => _seasonalityOptions,
  getEpdsType: () => _epdsType
}
